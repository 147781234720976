import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import InformationSection from "../../components/information-section/InformationSection"
import Layout from "../../components/layout/Layout"
import SubNav from "../../components/sub-nav/SubNav"
import SectionHeading from "../../components/UI/section-heading/SectionHeading"

const query = graphql`
  query {
    allStrapiProgramme {
      nodes {
        section_heading {
          data {
            section_heading
          }
        }
        programme_information {
          id
          main_heading {
            data {
              main_heading
            }
          }
          main_image {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
              }
            }
          }
          text {
            data {
              text
            }
          }
          gallery {
            id
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
            }
          }
          bottom_text {
            data {
              bottom_text
            }
          }
          logos {
            id
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
            }
          }
          order
        }
        programme_link {
          id
          link_name
          url
        }
      }
    }
  }
`

const Programme = () => {
  const programmeData = useStaticQuery(query)
  const data = programmeData.allStrapiProgramme.nodes[0].programme_information
  const linkData = programmeData.allStrapiProgramme.nodes[0].programme_link
  const sectionHeading =
    programmeData.allStrapiProgramme.nodes[0].section_heading.data
      .section_heading

  // const reversed = [...data].reverse()

  return (
    <Layout>
      <SubNav links={linkData} />
      <SectionHeading heading={sectionHeading} />
      <div>
        {data.map(item => {
          return <InformationSection key={item.id} data={item} />
        })}
      </div>
    </Layout>
  )
}

export default Programme
